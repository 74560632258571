/**
 * Custom component created by Zames
 *
 * Usage:
 * <NavigationTab orientation={'horizontal'}>
 *   <Link to={'/settings/team/team-members'}>
 *     <NavigationTabButton
 *       active={location.pathname.includes(
 *         '/settings/team/team-members',
 *       )}>
 *       Team Members
 *     </NavigationTabButton>
 *   </Link>
 * </NavigationTab>
 */
import React from 'react';

import { cn } from '../../lib/utils';
import { Button, ButtonProps } from './button';

// Define a context for the orientation
const NavigationTabContext = React.createContext<{
  orientation: 'horizontal' | 'vertical';
}>('horizontal');

export function useNavigationTabContext() {
  const context = React.useContext(NavigationTabContext);
  if (!context) {
    throw new Error(
      'useNavigationTabContext must be used within a NavigationTab',
    );
  }
  return context;
}

interface NavigationTabProps {
  orientation?: 'horizontal' | 'vertical';
}

const NavigationTab = React.forwardRef<HTMLDivElement, NavigationTabProps>(
  ({ orientation = 'horizontal', className, ...props }, ref) => {
    return (
      <NavigationTabContext.Provider value={{ orientation }}>
        <div
          ref={ref}
          className={cn(
            'gap-1',
            orientation === 'horizontal'
              ? '-ml-2 flex flex-row'
              : 'flex flex-col',
            className,
          )}
          {...props}
        />
      </NavigationTabContext.Provider>
    );
  },
);

NavigationTab.displayName = 'NavigationTab';

interface NavigationTabButtonProps extends Omit<ButtonProps, 'variant'> {
  active?: boolean;
}

const NavigationTabButton = React.forwardRef<
  HTMLButtonElement,
  NavigationTabButtonProps
>(({ className, active, children, ...props }, ref) => {
  const { orientation } = useNavigationTabContext();

  return (
    <Button
      ref={ref}
      variant="ghost"
      className={cn(
        'items-center p-2',
        orientation === 'vertical' && 'w-full justify-start',
        active
          ? 'text-flair hover:text-flair'
          : 'text-muted-foreground hover:text-foreground',
        className,
      )}
      {...props}>
      <span className="relative flex flex-col">
        {children}
        {/* The underline part, if orientation is horizontal */}
        {active && orientation === 'horizontal' && (
          <span className="bg-flair absolute -bottom-[10px] h-0.5 w-full rounded-full" />
        )}
        {active && orientation === 'vertical' && (
          <span className="bg-flair absolute -left-[8px] h-full w-0.5 rounded-full" />
        )}
      </span>
    </Button>
  );
});

NavigationTabButton.displayName = 'NavigationTabButton';

export { NavigationTab, NavigationTabButton };
