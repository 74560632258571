/**
 * Wraps <Input> with onChangeText handler
 */
import React from 'react';

import { Input } from './input';

export interface TextInputProps
  extends Omit<(typeof Input)['props'], 'onChange'> {
  onChangeText: (text: string) => void;
}

export const TextInput: React.FC<TextInputProps> = ({
  onChangeText,
  ...props
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeText(event.target.value);
  };

  return <Input onChange={handleChange} {...props} />;
};
