/**
 * Textarea component from shadcn/ui
 *
 * Edits:
 * 1. Modified to accept onChangeText prop
 */
import * as React from 'react';

import { cn } from '../../lib/utils';

const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.ComponentProps<'textarea'>
>(({ className, onChangeText, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        'border-input bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex min-h-[80px] w-full rounded-md border px-3 py-2 text-base focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
        className,
      )}
      ref={ref}
      onChange={(e) => {
        onChangeText?.(e.target.value);
      }}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
