/**
 * Box is a wrapper around <div> that adds the 'flex flex-col' className
 * HBox is a wrapper around <div> that adds the 'flex flex-row' className
 */
import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';

import { cn } from '../../lib/utils';

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <Comp className={cn('flex flex-col', className)} ref={ref} {...props} />
    );
  },
);
Box.displayName = 'Box';

export { Box };
