import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import Lottie from 'lottie-react';
import {
  Alert,
  Box,
  Button,
  Center,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CircleIcon,
  Divider,
  HStack,
  Input,
  Modal,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Orders, Rewards, Sellers } from '@waffle/common/src/models';
import { CountryUtil } from '@waffle/common/src/util/country/CountryUtil';
import { MoneyUtil } from '@waffle/common/src/util/money/MoneyUtil';
import { PhoneUtil } from '@waffle/common/src/util/phone/PhoneUtil';
import { useToast } from '@waffle/ui-web';

import lottieSuccessJson from '../../assets/lottie/95029-success.json';
import WaffleCountryCallingCodePicker from '../../components/WaffleCountryCallingCodePicker';
import WaffleErrorComponent from '../../components/WaffleErrorComponent';
import WaffleLoaderComponent from '../../components/WaffleLoaderComponent';
import ApiService from '../../utils/ApiService';
import { useAblySubscribe } from '../../utils/ably/useAblySubscribe';
import {
  orderCustomerMobileNumberAtom,
  useSubdomainSellerQuery,
} from '../../utils/store';

const OrderSummaryPage = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { orderId } = useParams<{ orderId: string }>();

  const [orderCustomerMobileNumber, setOrderCustomerMobileNumber] = useAtom(
    orderCustomerMobileNumberAtom,
  );

  const [isWhyShouldIJoinModalOpen, setWhyShouldIJoinModalOpen] =
    useState<boolean>(false);
  const [isMemberSignUpSuccessModalOpen, setMemberSignUpSuccessModalOpen] =
    useState(false);

  const [countryCode, setCountryCode] = useState<CountryUtil.CountryCode>(
    CountryUtil.CountryCode.SG,
  );
  const [mobileNumberInput, setMobileNumberInput] = useState<string>('');

  const { data: seller, isError: isSellerError } = useSubdomainSellerQuery();
  useEffect(() => {
    if (!!seller) {
      setCountryCode(seller.countryCode);
    }
  }, [seller]);

  const {
    data: order,
    isError: isOrderError,
    isPending: isOrderPending,
    isFetching: isOrderFetching,
  } = useQuery({
    queryKey: [`order`, orderId],
    queryFn: async (): Promise<Orders.Order> => {
      const res = await ApiService.request({
        method: 'GET',
        url: `/sellers/${seller.id}/orders/${orderId}`,
        params: {
          expand: ['order.location', 'order.customer.rewardsMembership'],
        },
      });
      return res.order;
    },
    enabled: !!orderId,
    gcTime: 0, // Don't cache this query ever
  });
  useAblySubscribe({
    channelName: `public:order=${orderId}`,
    eventName: Orders.OrderUpdatedEvent.TYPE,
    onEvent: (data: Orders.OrderUpdatedEvent) => {
      queryClient.invalidateQueries({
        queryKey: [`order`, orderId],
      });
    },
  });

  const [isOrderSummaryExpanded, setOrderSummaryExpanded] =
    useState<boolean>(false);

  const [isErrorTimerExpired, setIsErrorTimerExpired] =
    useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsErrorTimerExpired(true);
    }, 1000 * 30); // 30 secs
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const shouldShowErrorMessage: boolean = useMemo(() => {
    return (
      isErrorTimerExpired &&
      order.fulfillmentState !== Orders.Order_FulfillmentState.COMPLETED // TODO: Check for ACCEPTED state instead
    );
  }, [order, isErrorTimerExpired]);

  const callingCode = PhoneUtil.getCallingCode(countryCode);
  const mobileNumber = `${callingCode}${mobileNumberInput}`;

  const { mutate: doMemberSignUp, isPending: isMemberSignUpPending } =
    useMutation({
      mutationFn: async ({
        mobileNumber,
        seller,
        order,
      }: {
        mobileNumber: string;
        seller: Sellers.Seller;
        order: Orders.Order;
      }) => {
        // Check if this person is a member already
        const { customer } = await ApiService.request({
          method: 'GET',
          url: `/sellers/${seller?.id}/customers/mobile_number/${mobileNumber}`,
          params: {
            expand: ['customer.rewardsMembership'],
          },
          // Don't throw error on 400+ errors
          validateStatus: (status) => {
            return status < 500;
          },
        });
        const isExistingMember: boolean =
          !!customer && !!customer.rewardsMembership;

        // If this person is already a RewardsMember, this simply sets the order.customerId to the current member
        await ApiService.request({
          method: 'POST',
          url: `/sellers/${seller.id}/orders/${order.id}/create_rewards_membership`,
          data: {
            customerMapping: {
              mobileNumber: mobileNumber,
            },
          },
        });

        if (isExistingMember) {
          toast.show({
            status: 'error',
            title: `You are already a member of ${seller.name} :)`,
            description: `We've attached your membership to this Order. Enjoy your meal!`,
          });
          return;
        } else {
          setMemberSignUpSuccessModalOpen(true);
        }
      },
      onSuccess: async () => {
        // refetchOrders();
        // wait for invalidation to finish - reference: https://tkdodo.eu/blog/mastering-mutations-in-react-query#awaited-promises
        await queryClient.invalidateQueries({
          queryKey: [`order`, orderId],
        });
      },
      onError: () => {
        toast.show({
          status: 'error',
          title: 'Failed to join Rewards Programme',
          description: 'Please approach a staff member or try again later',
        });
      },
    });
  useEffect(() => {
    if (!order || !seller) {
      return;
    }

    if (!order.customer && !!orderCustomerMobileNumber) {
      doMemberSignUp(
        {
          mobileNumber: orderCustomerMobileNumber,
          seller: seller,
          order: order,
        },
        {
          onSettled: () => {
            setOrderCustomerMobileNumber('');
          },
        },
      );
    }
  }, [order, seller]);

  const handleMemberSignUp = () => {
    if (
      !PhoneUtil.isValidMobileNumber({
        text: mobileNumber,
        countryCode: countryCode,
      })
    ) {
      toast.show({
        status: 'error',
        title: 'Invalid Mobile Number',
        description: `Please enter a valid ${CountryUtil.getCountryConfig(countryCode).name} mobile number.`,
      });
      return;
    }
    doMemberSignUp({ mobileNumber: mobileNumber, seller, order });
  };

  if (isOrderError || isSellerError) {
    return <WaffleErrorComponent />;
  }

  if (isOrderPending || !seller) {
    return <WaffleLoaderComponent />;
  }

  return (
    <Box minHeight={'100%'} width={'100%'} alignItems={'center'}>
      {/*Order Status section*/}
      <Box width={'100%'} maxWidth={600} marginBottom={2}>
        <Box
          margin={2}
          padding={4}
          backgroundColor={'background.0'}
          borderRadius={10}>
          <Text variant={'subHeader'} marginBottom={2}>
            Order Status
          </Text>
          <Divider marginBottom={4} />
          {shouldShowErrorMessage && (
            <Box
              backgroundColor={'error.50'}
              borderWidth={1}
              borderColor={'error.600'}
              borderRadius={'md'}
              padding={2}
              marginBottom={8}>
              <Text color={'error.700'}>
                Your payment was successful but the store could not receive your
                order. Please approach a staff member for help.
              </Text>
            </Box>
          )}

          <Box
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}>
            {order.fulfillmentState !==
            Orders.Order_FulfillmentState.COMPLETED ? (
              <CheckCircleIcon
                color={'primary.600'}
                padding={4}
                marginRight={-6}
              />
            ) : (
              <CircleIcon color={'primary.600'} padding={4} marginRight={-6} />
            )}
            <Box
              flex={1}
              height={0.5}
              zIndex={-1}
              backgroundColor={
                order.fulfillmentState ===
                Orders.Order_FulfillmentState.COMPLETED
                  ? 'primary.600'
                  : 'background.300'
              }
            />
            {order.fulfillmentState ===
            Orders.Order_FulfillmentState.COMPLETED ? (
              <CheckCircleIcon
                color={'primary.600'}
                padding={4}
                marginLeft={-6}
              />
            ) : (
              <CircleIcon
                color={'background.300'}
                padding={4}
                marginLeft={-6}
              />
            )}
          </Box>
          <Box
            flexDirection={'row'}
            justifyContent={'space-between'}
            marginBottom={8}>
            <Text>Submitted</Text>
            <Text>Confirmed</Text>
          </Box>

          <Alert variant={'subtle'} colorScheme={'background'} status={'info'}>
            <HStack alignItems={'center'}>
              <Alert.Icon marginRight={2} color={'background.500'} />
              <Text flex={1}>
                Please screenshot or stay on this page to verify your order
              </Text>
            </HStack>
          </Alert>
        </Box>
      </Box>

      {/*Order Summary section*/}
      <Box width={'100%'} maxWidth={600} marginBottom={2}>
        <Box
          margin={2}
          padding={4}
          backgroundColor={'background.0'}
          borderRadius={10}>
          <Text variant={'subHeader'} marginBottom={2}>
            Order Summary
          </Text>
          <Divider marginY={2} />
          {!!order.ticketName && !!order.ticketValue && (
            <>
              <Center padding={8}>
                <Text variant={'label'}>{order.ticketName}</Text>
                <Text variant={'header'}>{order.ticketValue}</Text>
              </Center>
              <Divider />
            </>
          )}
          {isOrderSummaryExpanded && (
            <>
              <Box paddingY={2}>
                {!!order.location && (
                  <HStack
                    justifyContent={'space-between'}
                    alignItems={'baseline'}
                    marginBottom={2}>
                    <Text flex={1}>Location</Text>
                    <Text variant={'subText'} flex={1} marginLeft={2}>
                      {order.location.name}
                    </Text>
                  </HStack>
                )}

                {!!order.orderTypeName && (
                  <HStack
                    justifyContent={'space-between'}
                    alignItems={'baseline'}
                    marginBottom={2}>
                    <Text flex={1}>Order Type</Text>
                    <Text variant={'subText'} flex={1} marginLeft={2}>
                      {order.orderTypeName}
                    </Text>
                  </HStack>
                )}
                {!!order.ticketName && !!order.ticketValue && (
                  <HStack
                    justifyContent={'space-between'}
                    alignItems={'baseline'}
                    marginBottom={2}>
                    <Text flex={1}>{order.ticketName}</Text>
                    <Text variant={'subText'} flex={1} marginLeft={2}>
                      {order.ticketValue}
                    </Text>
                  </HStack>
                )}
                {!!order.saleNumber && (
                  <HStack
                    justifyContent={'space-between'}
                    alignItems={'baseline'}
                    marginBottom={2}>
                    <Text flex={1}>Sale Number</Text>
                    <Text variant={'subText'} flex={1} marginLeft={2}>
                      {order.saleNumber}
                    </Text>
                  </HStack>
                )}
                <HStack
                  justifyContent={'space-between'}
                  alignItems={'baseline'}
                  marginBottom={2}>
                  <Text flex={1}>Reference ID</Text>
                  <Text variant={'subText'} flex={1} marginLeft={2}>
                    {order.referenceId}
                  </Text>
                </HStack>
              </Box>
              <Divider />
              <VStack paddingY={2}>
                {order.lineItems.map((lineItem: Orders.OrderLineItem) => (
                  <HStack
                    key={lineItem.id}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Box flex={1}>
                      {/* line item */}
                      <HStack alignItems={'baseline'}>
                        <Text width={8}>{lineItem.quantity}</Text>
                        <Text flex={1}>
                          {lineItem.name}{' '}
                          {lineItem.selectedItemVariationName !== 'Default'
                            ? '(' + lineItem.selectedItemVariationName + ')'
                            : ''}
                        </Text>
                        <Text>
                          {' '}
                          {MoneyUtil.formatCurrency({
                            amount: lineItem.quantityPrice,
                            currencyCode: seller.defaultCurrencyCode,
                          })}
                        </Text>
                      </HStack>

                      {/* line item add ons */}
                      {lineItem.addOns.map((addOn: Orders.OrderAddOn) => (
                        <HStack
                          key={addOn.id}
                          justifyContent={'space-between'}
                          alignItems={'baseline'}>
                          <Text width={8} />
                          <Text flex={1}>{addOn.name}</Text>
                          <Text>
                            {MoneyUtil.formatCurrency({
                              amount: addOn.totalAmount,
                              currencyCode: seller.defaultCurrencyCode,
                            })}
                          </Text>
                        </HStack>
                      ))}
                      {/* TODO: Include applied discounts and applied extra charges and applied taxes to each line item ? */}
                      <HStack alignItems={'baseline'}>
                        <Text width={8} />
                        <Text flex={1}>{lineItem.note}</Text>
                      </HStack>
                    </Box>
                  </HStack>
                ))}
              </VStack>
              <Divider />
              <Box paddingY={2}>
                <VStack marginBottom={2}>
                  <HStack alignItems={'baseline'}>
                    <Text fontWeight={'medium'} flex={1}>
                      Subtotal
                    </Text>
                    <Text>
                      {' '}
                      {MoneyUtil.formatCurrency({
                        amount: order.subtotalAmount,
                        currencyCode: seller.defaultCurrencyCode,
                      })}
                    </Text>
                  </HStack>
                  {order.extraCharges.map(
                    (extraCharge: Orders.OrderExtraCharge) => (
                      <HStack key={extraCharge.id} alignItems={'baseline'}>
                        <Text flex={1}>
                          {extraCharge.name}{' '}
                          {extraCharge.isLineItemPricingInclusive
                            ? '(inclusive)'
                            : ''}
                        </Text>
                        <Text>
                          {MoneyUtil.formatCurrency({
                            amount: extraCharge.amount,
                            currencyCode: seller.defaultCurrencyCode,
                          })}
                        </Text>
                      </HStack>
                    ),
                  )}
                  {order.taxes.map((tax: Orders.OrderTax) => (
                    <HStack key={tax.id} alignItems={'baseline'}>
                      <Text flex={1}>
                        {tax.name}{' '}
                        {tax.isLineItemPricingInclusive ? '(inclusive)' : ''}
                      </Text>
                      <Text>
                        {MoneyUtil.formatCurrency({
                          amount: tax.amount,
                          currencyCode: seller.defaultCurrencyCode,
                        })}
                      </Text>
                    </HStack>
                  ))}
                </VStack>

                <Divider marginBottom={2} />

                <HStack alignItems={'baseline'}>
                  <Text fontWeight={'medium'} flex={1}>
                    Total
                  </Text>
                  {/* TODO: Same as above */}
                  <Text>
                    {' '}
                    {MoneyUtil.formatCurrency({
                      amount: order.totalAmount,
                      currencyCode: seller.defaultCurrencyCode,
                    })}
                  </Text>
                </HStack>
              </Box>
            </>
          )}

          <Box paddingY={2}>
            {isOrderSummaryExpanded ? (
              <HStack justifyContent={'center'}>
                <Button
                  colorScheme={'onSurface'}
                  variant={'ghost'}
                  rightIcon={<ChevronUpIcon color={'text.500'} size={6} />}
                  onPress={() =>
                    setOrderSummaryExpanded(!isOrderSummaryExpanded)
                  }>
                  Hide Order Details
                </Button>
              </HStack>
            ) : (
              <HStack justifyContent={'center'}>
                <Button
                  colorScheme={'onSurface'}
                  variant={'ghost'}
                  rightIcon={<ChevronDownIcon color={'text.500'} size={6} />}
                  onPress={() =>
                    setOrderSummaryExpanded(!isOrderSummaryExpanded)
                  }>
                  View Order Details
                </Button>
              </HStack>
            )}
          </Box>
        </Box>
      </Box>

      {/*Rewards section*/}
      {!!seller.rewardsProgramme &&
        !isOrderFetching &&
        !order.customer?.rewardsMembership &&
        !orderCustomerMobileNumber &&
        !isMemberSignUpPending && (
          <Box width={'100%'} maxWidth={600} marginBottom={2}>
            <Box
              margin={2}
              padding={4}
              backgroundColor={'background.0'}
              borderRadius={10}>
              <Box>
                <Text variant={'subHeader'}>Be a member at {seller.name}</Text>
                <Pressable
                  onPress={() => setWhyShouldIJoinModalOpen(true)}
                  _pressed={{ opacity: 0.5 }}>
                  <Text variant={'subText'} underline={true}>
                    Why should I join?
                  </Text>
                </Pressable>
                <Divider marginY={2} />

                <HStack>
                  <Input
                    flex={1}
                    keyboardType={'number-pad'}
                    value={mobileNumberInput}
                    onChangeText={(text) => {
                      if (!PhoneUtil.isValidMobileNumberInput(text)) {
                        return;
                      }
                      setMobileNumberInput(text);
                    }}
                    onSubmitEditing={handleMemberSignUp}
                    placeholder={'Enter Mobile Number'}
                    leftElement={
                      <WaffleCountryCallingCodePicker
                        countryCode={countryCode}
                        onCountryCodeChange={setCountryCode}
                        marginLeft={2}
                      />
                    }
                  />
                  <Button
                    marginLeft={2}
                    isLoading={isMemberSignUpPending}
                    onPress={handleMemberSignUp}>
                    Join Member
                  </Button>
                </HStack>
                <Text variant={'subText'} marginTop={2}>
                  By entering my phone number, I agree to receive notifications
                  and marketing communications via text. Unsubscribe anytime!
                </Text>
              </Box>
            </Box>
          </Box>
        )}

      {isWhyShouldIJoinModalOpen && !!seller && (
        <WhyShouldIJoinModal
          onClose={() => setWhyShouldIJoinModalOpen(false)}
          seller={seller}
        />
      )}

      {isMemberSignUpSuccessModalOpen && !!seller.rewardsProgramme && (
        <MemberSignUpSuccessModal
          onClose={() => setMemberSignUpSuccessModalOpen(false)}
          rewardsProgramme={seller.rewardsProgramme}
        />
      )}
    </Box>
  );
};

const MemberSignUpSuccessModal = ({
  onClose,
  rewardsProgramme,
}: {
  onClose: () => void;
  rewardsProgramme: Rewards.RewardsProgramme;
}) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Body>
          <Box alignItems={'center'}>
            <Lottie
              animationData={lottieSuccessJson}
              loop={false}
              autoplay={true}
              style={{
                height: 128,
                width: 128,
              }}
            />
            <Text variant={'subHeader'} textAlign={'center'}>
              Welcome to {rewardsProgramme.rewardsProgrammeName}!
            </Text>
            <Text marginTop={2} textAlign={'center'}>
              Use your phone number in the future to get points and earn
              rewards!
            </Text>
          </Box>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const WhyShouldIJoinModal = ({
  onClose,
  seller,
}: {
  onClose: () => void;
  seller: Sellers.Seller;
}) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>
          <Text variant={'header'}>Be a member at {seller.name}</Text>
        </Modal.Header>
        <Modal.Body>
          <Box flex={1} marginBottom={4}>
            <Box marginBottom={2}>
              <Text>Are you a frequent customer?</Text>
              <Text>
                {`Be a member at ${seller.name} to enjoy the following perks!`}
              </Text>
            </Box>

            <HStack alignItems={'center'} marginBottom={2}>
              <CheckCircleIcon color={'primary.500'} marginRight={2} size={4} />
              <Text variant={'subText'}>Earn points from visits</Text>
            </HStack>

            <HStack alignItems={'center'} marginBottom={2}>
              <CheckCircleIcon color={'primary.500'} marginRight={2} size={4} />
              <Text variant={'subText'}>Gain access to exclusive deals</Text>
            </HStack>
          </Box>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default OrderSummaryPage;
