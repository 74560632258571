import { DateTime, Interval } from 'luxon';
import { z } from 'zod';

export type DayOfWeek = z.infer<typeof DayOfWeek.schema>;
export namespace DayOfWeek {
  export const schema = z.enum([
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
    'SUN',
  ]);

  export const Enum = schema.enum;

  export const toHumanReadable = (dayOfWeek: DayOfWeek): string => {
    switch (dayOfWeek) {
      case DayOfWeek.Enum.MON: {
        return 'Monday';
      }
      case DayOfWeek.Enum.TUE: {
        return 'Tuesday';
      }
      case DayOfWeek.Enum.WED: {
        return 'Wednesday';
      }
      case DayOfWeek.Enum.THU: {
        return 'Thursday';
      }
      case DayOfWeek.Enum.FRI: {
        return 'Friday';
      }
      case DayOfWeek.Enum.SAT: {
        return 'Saturday';
      }
      case DayOfWeek.Enum.SUN: {
        return 'Sunday';
      }
      default: {
        throw new Error('Unrecognized DayOfWeek');
      }
    }
  };

  /**
   * MON = 1
   * TUE = 2
   * ...
   * SUN = 7
   *
   * Useful for mapping to Luxon WeekdayNumbers
   */
  export const toNumeric = (
    dayOfWeek: DayOfWeek,
  ): 1 | 2 | 3 | 4 | 5 | 6 | 7 => {
    switch (dayOfWeek) {
      case DayOfWeek.Enum.MON: {
        return 1;
      }
      case DayOfWeek.Enum.TUE: {
        return 2;
      }
      case DayOfWeek.Enum.WED: {
        return 3;
      }
      case DayOfWeek.Enum.THU: {
        return 4;
      }
      case DayOfWeek.Enum.FRI: {
        return 5;
      }
      case DayOfWeek.Enum.SAT: {
        return 6;
      }
      case DayOfWeek.Enum.SUN: {
        return 7;
      }
      default: {
        throw new Error('Unrecognized DayOfWeek');
      }
    }
  };
}

export type WeeklyPeriod = z.infer<typeof WeeklyPeriod.schema>;
export namespace WeeklyPeriod {
  export const schema = z.object({
    dayOfWeek: DayOfWeek.schema,
    startTime: z.string().time(), // ISO 8601 time HH:MM:SS[.s+], like '12:30:00'. Note that this is Seller's local time, not UTC
    endTime: z.string().time(), // ISO 8601 time HH:MM:SS[.s+], like '12:30:00'. Note that this is Seller's local time, not UTC
  });

  export const create = (args: Partial<WeeklyPeriod>): WeeklyPeriod => {
    return schema.parse(args);
  };

  export const isCurrentlyActive = ({
    localDateTimeIso = DateTime.local().toISO(),
    weeklyPeriod,
    weeklyPeriodTimeZone,
  }: {
    localDateTimeIso?: string;
    weeklyPeriod: WeeklyPeriod;
    weeklyPeriodTimeZone: string;
  }): boolean => {
    const weeklyPeriodInterval: Interval = toOngoingOrUpcomingInterval({
      localDateTimeIso: localDateTimeIso,
      weeklyPeriod: weeklyPeriod,
      weeklyPeriodTimeZone: weeklyPeriodTimeZone,
    });

    return weeklyPeriodInterval.contains(DateTime.fromISO(localDateTimeIso));
  };

  /**
   * Converts a WeeklyPeriod into an Luxon Interval of the ongoing or upcoming interval
   * If the endTime of the WeeklyPeriod has already passed, we consider the interval to be of the next week
   */
  const toOngoingOrUpcomingInterval = ({
    localDateTimeIso = DateTime.local().toISO(),
    weeklyPeriod,
    weeklyPeriodTimeZone,
  }: {
    localDateTimeIso?: string;
    weeklyPeriod: WeeklyPeriod;
    weeklyPeriodTimeZone: string;
  }): Interval => {
    const localDateTimeUtc = DateTime.fromISO(localDateTimeIso).toUTC();

    /**
     * Find the start DateTime of the weeklyPeriod, at weeklyPeriodTimeZone (Seller's timeZone)
     */
    const [startHours, startMinutes, startSeconds] = weeklyPeriod.startTime
      .split(':')
      .map(Number);
    let startDateTime = localDateTimeUtc
      .setZone(weeklyPeriodTimeZone, { keepLocalTime: true })
      .set({
        weekday: DayOfWeek.toNumeric(weeklyPeriod.dayOfWeek),
        hour: startHours,
        minute: startMinutes,
        second: startSeconds,
        millisecond: 0,
      });

    // If the start time is more than 24 hours in the past, we add a week
    if (
      startDateTime.toMillis() < localDateTimeUtc.minus({ days: 1 }).toMillis()
    ) {
      startDateTime = startDateTime.plus({ week: 1 });
    }

    /**
     * Try to find the end DateTime of the weeklyPeriod, at weeklyPeriodTimeZone (Seller's timeZone)
     */
    const [endHours, endMinutes, endSeconds] = weeklyPeriod.endTime
      .split(':')
      .map(Number);
    let endDateTime = startDateTime.setZone(weeklyPeriodTimeZone).set({
      hour: endHours,
      minute: endMinutes,
      second: endSeconds,
      millisecond: 0,
    });

    if (endDateTime.toMillis() <= startDateTime.toMillis()) {
      // If weeklyPeriod.endTime <= weeklyPeriod.startTime, we consider the endTime to be the next day
      endDateTime = endDateTime.plus({ days: 1 });
    }

    const interval = Interval.fromDateTimes(startDateTime, endDateTime);

    if (!interval.isValid) {
      throw new Error(
        `Invalid interval: ${interval.invalidReason}, ${interval.invalidExplanation}`,
      );
    }
    return Interval.fromDateTimes(startDateTime, endDateTime);
  };
}

/**
 * A WeeklySchedule is just an array of WeeklyPeriods,
 * but with additional constraints on top (eg. No more than 3 WeeklyPeriods per day, no overlapping WeeklyPeriods)
 */
export type WeeklySchedule = z.infer<typeof WeeklySchedule.schema>;
export namespace WeeklySchedule {
  export const schema = z.array(WeeklyPeriod.schema);

  export const create = (args: Partial<WeeklySchedule>): WeeklySchedule => {
    return schema.parse(args);
  };

  // As long as one of the WeeklyPeriods is currently active, we consider an entire WeeklySchedule to be active
  export const isCurrentlyActive = ({
    localDateTimeIso = DateTime.local().toISO(),
    weeklySchedule,
    weeklyScheduleTimeZone,
  }: {
    localDateTimeIso?: string;
    weeklySchedule: WeeklySchedule;
    weeklyScheduleTimeZone: string; // Timezone of the weeklySchedule
  }): boolean => {
    return weeklySchedule.some((weeklyPeriod) =>
      WeeklyPeriod.isCurrentlyActive({
        localDateTimeIso: localDateTimeIso,
        weeklyPeriod: weeklyPeriod,
        weeklyPeriodTimeZone: weeklyScheduleTimeZone,
      }),
    );
  };
}
