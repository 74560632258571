/**
 * Custom Link component that wraps an <a> tag, generated with v0.dev
 */
import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';

import { cn } from '../../lib/utils';

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  asChild?: boolean;
  shouldOpenInNewTab?: boolean;
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      asChild = false,
      className,
      external = false,
      shouldOpenInNewTab = false,
      href,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'a';

    const externalProps = shouldOpenInNewTab
      ? { target: '_blank', rel: 'noopener noreferrer' }
      : {};

    return (
      <Comp
        ref={ref}
        href={href}
        className={cn(className)}
        {...externalProps}
        {...props}
      />
    );
  },
);
Link.displayName = 'Link';

export { Link };
