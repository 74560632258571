/**
 * HBox is a convenience wrapper around <div> that adds the 'flex flex-row flex-wrap items-center' className
 */
import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';

import { cn } from '../../lib/utils';

export interface HBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

const HBox = React.forwardRef<HTMLDivElement, HBoxProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <Comp
        className={cn('flex flex-row items-center', className)}
        ref={ref}
        {...props}
      />
    );
  },
);
HBox.displayName = 'HBox';

export { HBox };
