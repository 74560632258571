import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import Lottie from 'lottie-react';
import { DateTime } from 'luxon';
import {
  Actionsheet,
  Box,
  Button,
  CheckIcon,
  ChevronDownIcon,
  Divider,
  HStack,
  Image,
  Input,
  ScrollView,
  Text,
  useDisclose,
} from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Common,
  Customers,
  Listings,
  OnlineOrdering,
  Orders,
  Sellers,
} from '@waffle/common/src/models';
import { CountryUtil } from '@waffle/common/src/util/country/CountryUtil';
import { PhoneUtil } from '@waffle/common/src/util/phone/PhoneUtil';
import { useToast } from '@waffle/ui-web';

import LottieClosed from '../../assets/lottie/51661-closed-tag.json';
import WaffleCountryCallingCodePicker from '../../components/WaffleCountryCallingCodePicker';
import WaffleErrorComponent from '../../components/WaffleErrorComponent';
import WaffleLoaderComponent from '../../components/WaffleLoaderComponent';
import ApiService from '../../utils/ApiService';
import {
  orderAtom,
  orderCustomerMobileNumberAtom,
  selfOrderingLinkIdAtom,
  useLocationsQuery,
  useOrderTypesQuery,
  useSelectedLocation,
  useSelectedOrderType,
  useSelfOrderingLinkQuery,
  useSubdomainSellerQuery,
} from '../../utils/store';

const LandingPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [order, setOrder] = useAtom(orderAtom);
  const [orderCustomerMobileNumber, setOrderCustomerMobileNumber] = useAtom(
    orderCustomerMobileNumberAtom,
  );
  const [countryCode, setCountryCode] = useState(CountryUtil.CountryCode.SG);
  const [mobileNumberInput, setMobileNumberInput] = useState<string>('');
  const mobileNumber: string = useMemo(
    () => `${PhoneUtil.getCallingCode(countryCode)}${mobileNumberInput}`,
    [countryCode, mobileNumberInput],
  );

  const {
    isOpen: isSelectOrderTypeOpen,
    onOpen: onSelectOrderTypeOpen,
    onClose: onSelectOrderTypeClose,
  } = useDisclose();

  const {
    isOpen: isSelectLocationOpen,
    onOpen: onSelectLocationOpen,
    onClose: onSelectLocationClose,
  } = useDisclose();

  const { data: seller, isError: isSellerError } = useSubdomainSellerQuery();
  useEffect(() => {
    if (!!seller) {
      setCountryCode(seller.countryCode);
    }
  }, [seller]);

  const selectedLocation = useSelectedLocation();
  const selectedOrderType = useSelectedOrderType();

  /**
   * Retrieve fields from query params
   */
  const [searchParams] = useSearchParams();
  const selfOrderingLinkId: string | undefined = searchParams.get('l');
  const [, setSelfOrderingLinkId] = useAtom(selfOrderingLinkIdAtom);
  useEffect(() => {
    setSelfOrderingLinkId(selfOrderingLinkId);
  }, [selfOrderingLinkId]);

  const { data: selfOrderingLink, isError: isSelfOrderingLinkError } =
    useSelfOrderingLinkQuery();
  useEffect(() => {
    if (!!selfOrderingLink) {
      setOrder((order) => {
        return {
          ...order,
          locationId: selfOrderingLink.onlineStore?.locationId,
          orderTypeId: selfOrderingLink.orderTypeId,
          ticketValue: selfOrderingLink.ticketValue,
        };
      });
    }
  }, [selfOrderingLink]);

  useEffect(() => {
    // reset Customer Number in local storage
    setOrderCustomerMobileNumber('');
  }, [setOrderCustomerMobileNumber]);

  const { data: locations, isError: isLocationsError } = useLocationsQuery();

  const { data: orderTypes, isError: isOrderTypesError } = useOrderTypesQuery();

  const proceedToCheckout = ({
    customer,
  }: {
    customer?: Customers.Customer;
  }) => {
    if (!seller) {
      throw new Error('No Seller');
    }

    if (!selectedLocation) {
      throw new Error('No Location selected');
    }

    if (!selectedOrderType) {
      throw new Error('No OrderType selected');
    }

    setOrder((order) =>
      Orders.Order.create({
        ...order,

        sellerId: seller.id,
        locationId: selectedLocation.id,
        deviceId: null,
        type: Orders.OrderType.SALE,
        saleNumber: null,
        appSource: Orders.OrderAppSource.SCAN_TO_ORDER,
        gatewaySource: Orders.OrderGatewaySource.STOREFRONT,

        fulfillments: [
          Orders.OrderPickupFulfillment.create({
            shouldApplyToAllLineItems: true,
          }),
        ],

        // Populate fields from OrderType
        orderTypeName: selectedOrderType.name ?? null,
        ticketType: selectedOrderType.ticketType ?? null,
        ticketName: selectedOrderType.ticketName ?? null,

        extraCharges:
          selectedOrderType.presetExtraCharges?.map(
            (extraCharge: Listings.ExtraCharge) =>
              Orders.OrderExtraCharge.fromListingExtraCharge({
                listingExtraCharge: extraCharge,
              }),
          ) ?? [],
        taxes:
          selectedOrderType.presetTaxes?.map((tax: Listings.Tax) =>
            Orders.OrderTax.fromListingTax({ listingTax: tax }),
          ) ?? [],

        customerId: customer?.id,
      }),
    );

    navigate('/checkout');
  };

  const { mutate: doMemberLogin, isPending: isDoMemberLoginPending } =
    useMutation({
      mutationFn: async ({
        sellerId,
        mobileNumber,
      }: {
        sellerId: string;
        mobileNumber: string;
      }) => {
        const { customer } = await ApiService.request({
          method: 'GET',
          url: `/sellers/${sellerId}/customers/mobile_number/${mobileNumber}`,
          params: {
            expand: ['customer.rewardsMembership'],
          },
          validateStatus: (status) => {
            if (status >= 400 && status !== 404) {
              return false;
            }
            return true;
          },
        });

        return !!customer && !!customer.rewardsMembership ? customer : null;
      },
      onSuccess: (customer: Customers.Customer | null) => {
        if (!!customer) {
          proceedToCheckout({ customer: customer });
        } else {
          // not member
          toast.show({
            status: 'success',
            title: `Welcome to ${seller.name}!`,
            description: 'Your membership will be created after payment.',
          });
          setOrderCustomerMobileNumber(mobileNumber);
          proceedToCheckout({});
        }
      },
      onError: (error) => {
        toast.show({
          status: 'error',
          title:
            'Failed to add your mobile number to the order, please try again!',
        });
      },
    });

  const handleMemberLogin = () => {
    if (!seller) {
      throw new Error('Encountered an error');
    }
    if (
      !PhoneUtil.isValidMobileNumber({
        text: mobileNumber,
        countryCode: countryCode,
      })
    ) {
      toast.show({
        status: 'error',
        title: 'Your mobile number is invalid!',
      });
      return;
    }
    doMemberLogin({
      sellerId: seller.id,
      mobileNumber: mobileNumber,
    });
  };

  const isContinueButtonDisabled: boolean = useMemo(
    () =>
      !selectedLocation ||
      !selectedOrderType ||
      (!!selectedOrderType &&
        selectedOrderType?.ticketType === Common.TicketType.TEXT && // Text ticketValue required
        !order.ticketValue),
    [selectedLocation, selectedOrderType, order],
  );

  // We temporarily block access without a SelfOrderingLink
  if (!selfOrderingLinkId) {
    return <WaffleErrorComponent />;
  }

  if (
    isSellerError ||
    isLocationsError ||
    isOrderTypesError ||
    isSelfOrderingLinkError
  ) {
    return <WaffleErrorComponent />;
  }

  if (
    !seller ||
    !locations ||
    !selfOrderingLink ||
    !selfOrderingLink.onlineStore
  ) {
    // Note: OrderType is only loaded once we've picked the location
    return <WaffleLoaderComponent />;
  }

  const sellingStatus = OnlineOrdering.OnlineStore.getSellingStatus({
    seller: seller,
    location: selfOrderingLink.onlineStore.location,
    scanToOrderStore: selfOrderingLink.onlineStore,
  });

  if (sellingStatus !== 'ACCEPTING_ORDERS') {
    return <StoreClosedScreen />;
  }

  return (
    <>
      <Box flex={1} width={'100%'} maxWidth={600} alignSelf={'center'}>
        <Box height={'100px'} />
        {/* Surface */}
        <Box borderRadius={10} backgroundColor={'white'} margin={2}>
          {/* Seller Logo Box*/}
          <Box
            justifyContent={'center'}
            alignItems={'center'}
            marginTop={'-80px'}>
            <Box
              width={'160px'}
              height={'160px'}
              alignItems={'center'}
              justifyContent={'center'}
              textAlign={'center'}
              backgroundColor={'background.0'}
              borderWidth={'1px'}
              borderColor={'onSurface.200'}
              borderRadius={'20px'}
              padding={1}>
              <Box
                height={'100%'}
                width={'100%'}
                justifyContent={'cetner'}
                alignItems={'center'}
                borderRadius={'16px'} // This borderRadius is slightly smaller than the parent's borderRadius intentionally, so that the border radius aligns
                overflow={'hidden'}>
                {!!seller.logoImage ? (
                  <Image
                    src={seller.logoImage.url}
                    resizeMode={'contain'}
                    alt={'seller-logo'}
                    size={'100%'}
                  />
                ) : (
                  <Text variant={'subHeader'}>{seller.name}</Text>
                )}
              </Box>
            </Box>
          </Box>

          {/* Store Description */}
          {!!selfOrderingLink.onlineStore.description && (
            <>
              <Box padding={6}>
                <Text
                  fontWeight={'light'}
                  color={'text.500'}
                  textAlign={'center'}>
                  {selfOrderingLink.onlineStore.description}
                </Text>
              </Box>
              <Divider marginY={2} backgroundColor={'onSurface.200'} />
            </>
          )}

          {/* Order Details */}
          <Box padding={6}>
            {/* <Divider marginBottom={4} /> */}
            <Text variant={'subText'}>Location</Text>

            <Button
              variant={'ghost'}
              colorScheme={'onSurface'}
              backgroundColor={'background.50'}
              paddingY={3}
              paddingX={2}
              marginBottom={2}
              isDisabled={!!selfOrderingLink?.locationId} // Don't allow edit if url pass in locationId
              onPress={onSelectLocationOpen}
              _stack={{
                flex: 1,
                justifyContent: 'space-between',
              }}
              rightIcon={
                !selfOrderingLink?.locationId ? (
                  <ChevronDownIcon size={6} color={'gray.500'} />
                ) : null
              }>
              <Text>{selectedLocation?.name ?? 'Select Location'}</Text>
            </Button>

            {!!orderTypes && orderTypes.length > 0 && (
              <>
                <Text variant={'subText'}>Order Type</Text>
                <Button
                  variant={'ghost'}
                  colorScheme={'onSurface'}
                  backgroundColor={'background.50'}
                  paddingY={3}
                  paddingX={2}
                  marginBottom={2}
                  isDisabled={!!selfOrderingLink?.orderTypeId} // Don't allow edit if url pass in order type id
                  onPress={onSelectOrderTypeOpen}
                  _stack={{
                    flex: 1,
                    justifyContent: 'space-between',
                  }}
                  rightIcon={
                    !selfOrderingLink?.orderTypeId ? (
                      <ChevronDownIcon size={6} color={'gray.500'} />
                    ) : undefined
                  }>
                  <Text>{selectedOrderType?.name ?? 'Select Order Type'}</Text>
                </Button>
              </>
            )}

            {selectedOrderType?.ticketType === Common.TicketType.TEXT && (
              <>
                <Text variant={'subText'}>{selectedOrderType.ticketName}</Text>
                {/* Autofill this with payload from QR code */}
                <Input
                  paddingY={3}
                  paddingX={2}
                  marginBottom={2}
                  backgroundColor={'background.50'}
                  color={'gray.500'}
                  isDisabled={!!selfOrderingLink?.ticketValue} // Only allow entering of ticket value e.g. table number if not passed in from url. Otherwise ignore
                  defaultValue={order.ticketValue}
                  onChangeText={(text) => {
                    setOrder((order) => ({
                      ...order,
                      ticketValue: text,
                    }));
                  }}
                />
              </>
            )}
          </Box>

          <Box padding={6}>
            <Button
              isDisabled={isContinueButtonDisabled}
              onPress={() => proceedToCheckout({})}>
              {!!seller.rewardsProgramme ? 'Continue as Guest' : 'Continue'}
            </Button>
            {!!seller.rewardsProgramme && (
              <>
                <HStack marginY={4} alignItems={'center'}>
                  <Divider flex={1} />
                  <Text marginX={4} variant={'subText'}>
                    or
                  </Text>
                  <Divider flex={1} />
                </HStack>

                <HStack>
                  <Input
                    flex={1}
                    keyboardType={'number-pad'}
                    value={mobileNumberInput}
                    placeholder={'Enter Mobile Number'}
                    onChangeText={(text) => {
                      if (!PhoneUtil.isValidMobileNumberInput(text)) {
                        return;
                      }
                      setMobileNumberInput(text);
                    }}
                    onSubmitEditing={handleMemberLogin}
                    leftElement={
                      <WaffleCountryCallingCodePicker
                        countryCode={countryCode}
                        onCountryCodeChange={setCountryCode}
                        marginLeft={2}
                      />
                    }
                  />

                  <Button
                    isLoading={isDoMemberLoginPending}
                    isDisabled={isContinueButtonDisabled}
                    marginLeft={2}
                    onPress={handleMemberLogin}>
                    Continue
                  </Button>
                </HStack>
                <Text variant={'subText'}>
                  Use your mobile number to collect points and redeem rewards!
                </Text>
              </>
            )}
          </Box>
        </Box>
      </Box>

      {!selfOrderingLink?.locationId &&
        !!locations && ( // Don't even render if cannot edit
          <Actionsheet
            isOpen={isSelectLocationOpen}
            onClose={onSelectLocationClose}>
            <Actionsheet.Content>
              <ScrollView width={'100%'}>
                {locations.map((location: Sellers.Location) => (
                  <Actionsheet.Item
                    key={location.id}
                    onPress={() => {
                      setOrder((order) => {
                        return {
                          ...order,
                          locationId: location.id,
                        };
                      });
                      onSelectLocationClose();
                    }}
                    endIcon={
                      selectedLocation?.id === location.id ? (
                        <CheckIcon size={4} />
                      ) : undefined
                    }
                    _stack={{ flex: 1, justifyContent: 'space-between' }}>
                    {location.name}
                  </Actionsheet.Item>
                ))}
              </ScrollView>
            </Actionsheet.Content>
          </Actionsheet>
        )}

      {!selfOrderingLink?.orderTypeId && !!orderTypes && (
        <Actionsheet
          isOpen={isSelectOrderTypeOpen}
          onClose={onSelectOrderTypeClose}>
          <Actionsheet.Content>
            <ScrollView width={'100%'}>
              {orderTypes.map((orderType: Listings.OrderType, index) => (
                <Actionsheet.Item
                  key={orderType.id}
                  onPress={() => {
                    setOrder((order) => {
                      return {
                        ...order,
                        orderTypeId: orderType.id,
                      };
                    });
                    onSelectOrderTypeClose();
                  }}
                  endIcon={
                    selectedOrderType?.id === orderType.id ? (
                      <CheckIcon size={4} />
                    ) : undefined
                  }
                  _stack={{
                    flex: 1,
                    justifyContent: 'space-between',
                  }}>
                  {orderType.name}
                </Actionsheet.Item>
              ))}
            </ScrollView>
          </Actionsheet.Content>
        </Actionsheet>
      )}
    </>
  );
};

const StoreClosedScreen = () => {
  return (
    <Box
      flex={1}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}>
      <Box
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
        maxWidth={600}
        padding={8}>
        <Lottie animationData={LottieClosed} loop={true} autoplay={true} />
        <Text variant={'label'}>
          This store is currently closed, please check back later!
        </Text>
      </Box>
    </Box>
  );
};

export default LandingPage;
