import { useMutation, useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import {
  Box,
  Button,
  ChevronLeftIcon,
  Divider,
  HStack,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Orders } from '@waffle/common/src/models';
import { MoneyUtil } from '@waffle/common/src/util/money/MoneyUtil';
import { useToast } from '@waffle/ui-web';

import WaffleErrorComponent from '../../components/WaffleErrorComponent';
import WaffleLoaderComponent from '../../components/WaffleLoaderComponent';
import ApiService from '../../utils/ApiService';
import { orderAtom, useSubdomainSellerQuery } from '../../utils/store';
import OrderLineItemEditModal from './OrderLineItemEditModal';

const ReviewOrderPage = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useAtom(orderAtom);
  const toast = useToast();

  const { data: seller, isError: isSellerError } = useSubdomainSellerQuery();
  const location = useLocation();
  const selectedOrderLineItem: Orders.OrderLineItem | undefined =
    location.state?.selectedOrderLineItem;

  const {
    data: calculatedOrder,
    isPending: isCalculatedOrderPending,
    isError: isCalculatedOrderError,
  } = useQuery({
    queryKey: ['order', 'calculate', order], // re-calculate whenever order reference changes
    queryFn: async () => {
      const { order: calculatedOrder } = await ApiService.request({
        method: 'POST',
        url: `/sellers/${seller.id}/orders/calculate`,
        data: {
          order: order,
        },
      });
      return calculatedOrder;
    },
  });

  const { mutate: createOrder, isPending: isCreateOrderPending } = useMutation({
    mutationFn: async () => {
      const { order: resOrder } = await ApiService.request({
        method: 'POST',
        url: `/sellers/${seller.id}/orders`,
        data: {
          order: order,
        },
      });
      if (!resOrder) {
        throw new Error('Order was not successfully created');
      }

      const res = await ApiService.request({
        method: 'POST',
        url: `/sellers/${seller.id}/orders/${resOrder.id}/pay`,
        data: {
          payment: {
            currency: seller.defaultCurrencyCode,
          },
        },
      });
      if (!res.stripe?.clientSecret) {
        throw new Error('No client secret found');
      }

      navigate(
        `/orders/${resOrder.id}/pay?payment_intent_client_secret=${res.stripe.clientSecret}`,
      );
    },
    onError: () => {
      toast.show({
        status: 'error',
        title: 'Failed to place your Order',
        description: 'Please approach a staff member to continue ordering',
      });
    },
  });

  const { mutate: createFreeOrder, isPending: isCreateFreeOrderPending } =
    useMutation({
      mutationFn: async () => {
        if (order.totalAmount !== 0 || order.lineItems.length === 0) {
          throw new Error('Invalid free Order');
        }
        const orderWithTender: Orders.Order = Orders.Order.upsertOrderTender(
          order,
          Orders.OrderCashTender.create({
            cashDetails: {
              tenderAmount: 0,
              changeAmount: 0,
              roundingAmount: 0,
            },
          }),
        );
        const { order: resOrder } = await ApiService.request({
          method: 'POST',
          url: `/sellers/${seller.id}/orders`,
          data: {
            order: orderWithTender,
          },
        });
        if (!resOrder) {
          throw new Error('Order was not successfully created');
        }

        navigate(`/orders/${resOrder.id}/summary`);
      },
      onError: () => {
        toast.show({
          status: 'error',
          title: 'Failed to place your Order',
          description: 'Please approach a staff member to continue ordering',
        });
      },
    });

  // If they've removed all the items
  if (order.lineItems.length < 1) {
    navigate('/checkout', { replace: true });
    return null;
  }

  if (isCalculatedOrderError || isSellerError) {
    return <WaffleErrorComponent />;
  }

  if (isCalculatedOrderPending || !seller) {
    return <WaffleLoaderComponent />;
  }

  return (
    <Box
      minHeight={'100%'}
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}>
      <Box width={'100%'} maxWidth={600}>
        <HStack paddingY={2}>
          <Button
            leftIcon={<ChevronLeftIcon size={4} color={'text.400'} />}
            variant={'ghost'}
            colorScheme={'onSurface'}
            onPress={() => navigate(-1)}>
            Main Menu
          </Button>
        </HStack>
        <Box
          margin={2}
          padding={4}
          backgroundColor={'background.0'}
          borderRadius={24}>
          <Text variant={'subHeader'} marginBottom={2}>
            Review Order
          </Text>
          <Divider marginBottom={2} />
          <VStack marginBottom={4}>
            {calculatedOrder.lineItems.map((lineItem: Orders.OrderLineItem) => (
              <Pressable
                key={lineItem.id}
                paddingY={4}
                paddingX={2}
                borderRadius={8}
                _hover={{ backgroundColor: 'background.50' }}
                _pressed={{ backgroundColor: 'background.100' }}
                onPress={() =>
                  navigate('/checkout/review', {
                    state: { selectedOrderLineItem: lineItem },
                  })
                }>
                {/* line item */}
                <HStack alignItems={'baseline'}>
                  <Text width={8}>{lineItem.quantity}x</Text>
                  <Text flex={1}>
                    {lineItem.name}{' '}
                    {lineItem.selectedItemVariationName !== 'Default'
                      ? '(' + lineItem.selectedItemVariationName + ')'
                      : ''}
                  </Text>
                  <Text>
                    {MoneyUtil.formatCurrency({
                      amount: lineItem.quantityPrice,
                      currencyCode: seller.defaultCurrencyCode,
                    })}
                  </Text>
                </HStack>
                {/* line item add ons */}
                {lineItem.addOns.map((addOn: Orders.OrderAddOn) => (
                  <HStack key={addOn.id} alignItems={'baseline'}>
                    <Text width={8} />
                    <Text flex={1}>
                      {addOn.quantity}x {addOn.name}
                    </Text>
                    <Text>
                      {' '}
                      {MoneyUtil.formatCurrency({
                        amount: addOn.totalAmount,
                        currencyCode: seller.defaultCurrencyCode,
                      })}
                    </Text>
                  </HStack>
                ))}
                <HStack alignItems={'baseline'}>
                  <Text width={8} />
                  <Text variant={'subText'} flex={1}>
                    {lineItem.note}
                  </Text>
                </HStack>
                <HStack alignItems={'baseline'}>
                  <Text width={8} />
                  <Text
                    color={'primary.600'}
                    fontSize={'xs'}
                    fontWeight={'medium'}>
                    Edit
                  </Text>
                </HStack>
              </Pressable>
            ))}
          </VStack>

          <Divider marginBottom={5} />

          <VStack marginBottom={5}>
            <HStack justifyContent={'space-between'}>
              <Text variant={'label'}>Subtotal</Text>
              <Text variant={'label'}>
                {MoneyUtil.formatCurrency({
                  amount: calculatedOrder.subtotalAmount,
                  currencyCode: seller.defaultCurrencyCode,
                })}
              </Text>
            </HStack>
            {calculatedOrder.discounts.map((discount: Orders.OrderDiscount) => (
              <HStack key={discount.id} alignItems={'baseline'}>
                <Text flex={1}>{discount.name}</Text>
                <Text>
                  {' '}
                  {MoneyUtil.formatCurrency({
                    amount: discount.amount,
                    currencyCode: seller.defaultCurrencyCode,
                  })}
                </Text>
              </HStack>
            ))}
            {calculatedOrder.extraCharges.map(
              (extraCharge: Orders.OrderExtraCharge) => (
                <HStack key={extraCharge.id} alignItems={'baseline'}>
                  <Text flex={1}>
                    {extraCharge.name}{' '}
                    {extraCharge.isLineItemPricingInclusive
                      ? '(inclusive)'
                      : ''}
                  </Text>
                  <Text>
                    {' '}
                    {MoneyUtil.formatCurrency({
                      amount: extraCharge.amount,
                      currencyCode: seller.defaultCurrencyCode,
                    })}
                  </Text>
                </HStack>
              ),
            )}
            {calculatedOrder.taxes.map((tax: Orders.OrderTax) => (
              <HStack key={tax.id} alignItems={'baseline'}>
                <Text flex={1}>
                  {tax.name}{' '}
                  {tax.isLineItemPricingInclusive ? '(inclusive)' : ''}
                </Text>
                <Text>
                  {' '}
                  {MoneyUtil.formatCurrency({
                    amount: tax.amount,
                    currencyCode: seller.defaultCurrencyCode,
                  })}
                </Text>
              </HStack>
            ))}
          </VStack>

          <Divider marginBottom={5} />

          <HStack justifyContent={'space-between'}>
            <Text variant={'label'}>Total</Text>
            <Text variant={'label'}>
              {MoneyUtil.formatCurrency({
                amount: calculatedOrder.totalAmount,
                currencyCode: seller.defaultCurrencyCode,
              })}
            </Text>
          </HStack>
        </Box>
      </Box>
      <>
        {/*Spacer for footer*/}
        <Box height={20} />
        {/*Footer*/}
        <Box
          position={'fixed'}
          bottom={0}
          height={20}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          backgroundColor={'background.0'}>
          <Box height={'100%'} width={'100%'} maxWidth={600} padding={4}>
            <Button
              size={'lg'}
              flex={1}
              isDisabled={calculatedOrder.lineItems.length === 0}
              onPress={() => {
                if (calculatedOrder.lineItems.length === 0) {
                  // Invalid case, since a) we redirect user away from this page when there are no more line items, or b) don't allow navigation to this page if there are no line items
                  throw new Error('There are no OrderLineItem in this Order');
                } else if (calculatedOrder.totalAmount === 0) {
                  createFreeOrder();
                } else {
                  createOrder();
                }
              }}
              isLoading={isCreateOrderPending || isCreateFreeOrderPending}>
              Continue to Payment
            </Button>
          </Box>
        </Box>
      </>

      {selectedOrderLineItem && (
        <OrderLineItemEditModal
          orderLineItem={selectedOrderLineItem}
          onSave={(orderLineItem: Orders.OrderLineItem) => {
            setOrder(Orders.Order.upsertOrderLineItem(order, orderLineItem));
            navigate(-1);
          }}
          onClose={() => navigate(-1)}
          onRemove={(orderLineItemId) => {
            setOrder(
              Orders.Order.removeOrderLineItemById(order, orderLineItemId),
            );
            navigate(-1);
          }}
        />
      )}
    </Box>
  );
};

export default ReviewOrderPage;
