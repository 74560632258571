import * as React from 'react';

import { cn } from '../../lib/utils';

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  columns?: number;
  gap?: number;
  children: React.ReactNode;
}

const Grid = React.forwardRef<HTMLDivElement, GridProps>(
  ({ className, columns = 1, gap = 4, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('grid', `grid-cols-${columns}`, `gap-${gap}`, className)}
        {...props}>
        {children}
      </div>
    );
  },
);
Grid.displayName = 'Grid';

export { Grid };
