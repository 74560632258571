import { Loader2 } from 'lucide-react';
import React from 'react';

import { cn } from '../../lib/utils';

const Spinner = ({ className }: { className?: string }) => {
  return <Loader2 className={cn('animate-spin', className)} />;
};

export { Spinner };
