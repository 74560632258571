/**
 * Container component generated using v0.dev
 * This constrains the width of the content to a max width depending on the viewport width, and centers all content by default
 */
import * as React from 'react';

import { cn } from '../../lib/utils';
import { Box } from './box';

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: React.ElementType;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, as: Component = Box, ...props }, ref) => {
    return (
      <Component
        ref={ref}
        className={cn(
          'mx-auto w-full px-1 sm:px-2 lg:px-4',
          'max-w-[1440px]',
          'sm:max-w-[540px]',
          'md:max-w-[720px]',
          'lg:max-w-[960px]',
          'xl:max-w-[1140px]',
          '2xl:max-w-[1320px]',
          className,
        )}
        {...props}
      />
    );
  },
);
Container.displayName = 'Container';

export { Container };
