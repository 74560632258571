import { DateTime, Interval, WeekdayNumbers } from 'luxon';
import { z } from 'zod';

//TODO: Fill up the list of permissions in future

export enum Permission {
  // AUTH
  'AUTH.SELLER_DASHBOARD.LOGIN' = 'AUTH.SELLER_DASHBOARD.LOGIN',
  'AUTH.POS.LOGIN' = 'AUTH.POS.LOGIN',

  // ORDERS
  'ORDERS.ORDER.APPLY_DISCOUNT' = 'ORDERS.ORDER.APPLY_DISCOUNT',
  'ORDERS.ORDER.VOID' = 'ORDER.ORDER.VOID',
  'ORDERS.ORDER.REFUND' = 'ORDER.ORDER.REFUND',
  'ORDERS.ORDER.ADD_CUSTOMER_TO_COMPLETED_ORDER' = 'ORDERS.ORDER.ADD_CUSTOMER_TO_COMPLETED_ORDER',

  // POS
  'POS.REGISTER_SHIFT_REPORT.READ_EXPECTED_AMOUNTS' = 'POS.REGISTER_SHIFT_REPORT.READ_EXPECTED_AMOUNTS',
  'POS.CONFIGURATION.READ' = 'POS.CONFIGURATION.READ',
  'POS.CONFIGURATION.WRITE' = 'POS.CONFIGURATION.WRITE',

  // LISTINGS
  'LISTINGS.READ' = 'LISTINGS.READ',
  'LISTINGS.WRITE' = 'LISTINGS.WRITE',

  // REPORTS
  'REPORTS.SALES_SUMMARY.READ' = 'REPORTS.SALES_SUMMARY.READ',
  'REPORTS.PAYMENT_METHODS_SUMMARY.READ' = 'REPORTS.PAYMENT_METHODS_SUMMARY.READ',
  'REPORTS.ITEMS_SUMMARY.READ' = 'REPORTS.ITEMS_SUMMARY.READ',
  'REPORTS.CATEGORIES_SUMMARY.READ' = 'REPORTS.CATEGORIES_SUMMARY.READ',
  'REPORTS.ADD_ONS_SUMMARY.READ' = 'REPORTS.ADD_ONS_SUMMARY.READ',
  'REPORTS.DISCOUNTS_SUMMARY.READ' = 'REPORTS.DISCOUNTS_SUMMARY.READ',
  'REPORTS.VOUCHERS_SUMMARY.READ' = 'REPORTS.VOUCHERS_SUMMARY.READ',
  'REPORTS.VOUCHERS_HISTORY.READ' = 'REPORTS.VOUCHERS_HISTORY.READ',
  'REPORTS.REGISTER_SHIFTS_HISTORY.READ' = 'REPORTS.REGISTER_SHIFTS_HISTORY.READ',
  'REPORTS.CUSTOM_REPORT.READ' = 'REPORTS.CUSTOM_REPORT.READ',
  'REPORTS.CUSTOM_REPORT.WRITE' = 'REPORTS.CUSTOM_REPORT.WRITE',

  // EXPORTS
  'SALES.SALES_HISTORY.EXPORT' = 'SALES.SALES_HISTORY.EXPORT',
  'REPORTS.SALES_SUMMARY.EXPORT' = 'REPORTS.SALES_SUMMARY.EXPORT',
  'REPORTS.PAYMENT_METHODS_SUMMARY.EXPORT' = 'REPORTS.PAYMENT_METHODS_SUMMARY.EXPORT',
  'REPORTS.ITEMS_SUMMARY.EXPORT' = 'REPORTS.ITEMS_SUMMARY.EXPORT',
  'REPORTS.CATEGORIES_SUMMARY.EXPORT' = 'REPORTS.CATEGORIES_SUMMARY.EXPORT',
  'REPORTS.ADD_ONS_SUMMARY.EXPORT' = 'REPORTS.ADD_ONS_SUMMARY.EXPORT',
  'REPORTS.DISCOUNTS_SUMMARY.EXPORT' = 'REPORTS.DISCOUNTS_SUMMARY.EXPORT',
  'REPORTS.VOUCHERS_SUMMARY.EXPORT' = 'REPORTS.VOUCHERS_SUMMARY.EXPORT',
  'REPORTS.VOUCHERS_HISTORY.EXPORT' = 'REPORTS.VOUCHERS_HISTORY.EXPORT',
  'REPORTS.REGISTER_SHIFTS_HISTORY.EXPORT' = 'REPORTS.REGISTER_SHIFTS_HISTORY.EXPORT',

  // HISTORY
  'SALES.SALES_HISTORY.READ' = 'SALES.SALES_HISTORY.READ',
  'INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.READ' = 'INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.READ',
  'INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.EXPORT' = 'INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.EXPORT',

  // ONLINE ORDERING
  'ONLINE_ORDERING.ONLINE_STORE.READ' = 'ONLINE_ORDERING.ONLINE_STORE.READ',
  'ONLINE_ORDERING.ONLINE_STORE.WRITE' = 'ONLINE_ORDERING.ONLINE_STORE.WRITE',

  // REWARDS
  'REWARDS.CAMPAIGN.READ' = 'REWARDS.CAMPAIGN.READ',
  'REWARDS.CAMPAIGN.WRITE' = 'REWARDS.CAMPAIGN.WRITE',
  'REWARDS.REWARDS_LISTING.READ' = 'REWARDS.REWARDS_LISTING.READ',
  'REWARDS.REWARDS_LISTING.WRITE' = 'REWARDS.REWARDS_LISTING.WRITE',
  'REWARDS.REWARDS_PROGRAMME.WRITE' = 'REWARDS.REWARDS_PROGRAMME.WRITE',
  'REWARDS.REWARDS_PROGRAMME.READ' = 'REWARDS.REWARDS_PROGRAMME.READ',
  'REWARDS.REWARDS_MEMBER.ADD_POINTS' = 'REWARDS.REWARDS_MEMBER.ADD_POINTS',
  'REWARDS.REWARDS_MEMBER.REMOVE_POINTS' = 'REWARDS.REWARDS_MEMBER.REMOVE_POINTS',
  /**
   * TODO: Deprecate in favour of REWARDS.REWARDS_MEMBER.ADD_POINTS
   *
   * Migration:
   * UPDATE custom_role
   * SET permissions = array_remove(permissions, 'REWARDS.REWARDS_MEMBER.ADD_POINTS');
   */
  'REWARDS.POINT_TRANSACTION.ISSUE_POINTS' = 'REWARDS.POINT_TRANSACTION.ISSUE_POINTS',

  // CUSTOMERS
  'CUSTOMERS.CUSTOMER.READ' = 'CUSTOMERS.CUSTOMER.READ',
  'CUSTOMERS.CUSTOMER_SET.READ' = 'CUSTOMERS.CUSTOMER_SET.READ',
  'CUSTOMERS.CUSTOMER_SET.WRITE' = 'CUSTOMERS.CUSTOMER_SET.WRITE',

  // BUSINESS SETTINGS
  'BUSINESS.SETTINGS.READ' = 'BUSINESS.SETTINGS.READ',
  'BUSINESS.SETTINGS.WRITE' = 'BUSINESS.SETTINGS.WRITE',

  // TEAMS
  'TEAMS.ROLE.READ' = 'TEAMS.ROLE.READ',
  'TEAMS.ROLE.WRITE' = 'TEAMS.ROLE.WRITE',
  'TEAMS.TEAM_MEMBER.READ' = 'TEAMS.TEAM_MEMBER.READ',
  'TEAMS.TEAM_MEMBER.WRITE' = 'TEAMS.TEAM_MEMBER.WRITE',
}

// Map permissions to human readable version
export const permissionLabels: Partial<
  Record<Permission, { label: string; description?: string }>
> = {
  // AUTH
  [Permission['AUTH.SELLER_DASHBOARD.LOGIN']]: {
    label: 'Seller Dashboard Access',
  },
  [Permission['AUTH.POS.LOGIN']]: { label: 'POS Access' },

  // ORDERS
  [Permission['ORDERS.ORDER.APPLY_DISCOUNT']]: {
    label: 'Apply Discounts to a Transaction',
  },
  [Permission['ORDERS.ORDER.VOID']]: { label: 'Void Unpaid Orders' },
  [Permission['ORDERS.ORDER.REFUND']]: {
    label: 'Issue Refund for Paid Orders',
  },
  [Permission['ORDERS.ORDER.ADD_CUSTOMER_TO_COMPLETED_ORDER']]: {
    label: 'Add Customer to a Completed Order',
  },

  // SHIFTS
  [Permission['REPORTS.REGISTER_SHIFTS_HISTORY.READ']]: {
    label: 'View Register Shift History',
  },

  // REPORTS
  [Permission['REPORTS.SALES_SUMMARY.READ']]: {
    label: 'View Sales Summary',
  },
  [Permission['REPORTS.PAYMENT_METHODS_SUMMARY.READ']]: {
    label: 'View Payment Methods Summary',
  },
  [Permission['REPORTS.ITEMS_SUMMARY.READ']]: {
    label: 'View Items Summary',
  },
  [Permission['REPORTS.CATEGORIES_SUMMARY.READ']]: {
    label: 'View Categories Summary',
  },
  [Permission['REPORTS.ADD_ONS_SUMMARY.READ']]: {
    label: 'View Add-Ons Summary',
  },
  [Permission['REPORTS.DISCOUNTS_SUMMARY.READ']]: {
    label: 'View Discount Summary',
  },
  [Permission['REPORTS.VOUCHERS_SUMMARY.READ']]: {
    label: 'View Vouchers Summary',
  },
  [Permission['REPORTS.VOUCHERS_HISTORY.READ']]: {
    label: 'View Vouchers History',
  },
  // [Permission['REPORTS.CUSTOM_REPORT.READ']]: { label: 'View Custom Reports' },
  [Permission['REPORTS.CUSTOM_REPORT.WRITE']]: {
    label: 'Manage Custom Reports',
  },

  // EXPORTS
  [Permission['SALES.SALES_HISTORY.EXPORT']]: { label: 'Export Sales History' },
  [Permission['REPORTS.SALES_SUMMARY.EXPORT']]: {
    label: 'Export Sales Summary',
  },
  [Permission['REPORTS.PAYMENT_METHODS_SUMMARY.EXPORT']]: {
    label: 'Export Payment Methods Summary',
  },
  [Permission['REPORTS.ITEMS_SUMMARY.EXPORT']]: {
    label: 'Export Items Summary',
  },
  [Permission['REPORTS.CATEGORIES_SUMMARY.EXPORT']]: {
    label: 'Export Categories Summary',
  },
  [Permission['REPORTS.ADD_ONS_SUMMARY.EXPORT']]: {
    label: 'Export Add Ons Summary',
  },
  [Permission['REPORTS.DISCOUNTS_SUMMARY.EXPORT']]: {
    label: 'Export Discount Summary',
  },
  [Permission['REPORTS.VOUCHERS_SUMMARY.EXPORT']]: {
    label: 'Export Vouchers Summary',
  },
  [Permission['REPORTS.VOUCHERS_HISTORY.EXPORT']]: {
    label: 'Export Voucher History',
  },
  [Permission['REPORTS.REGISTER_SHIFTS_HISTORY.EXPORT']]: {
    label: 'Export Register Shift History',
  },

  // HISTORY
  [Permission['SALES.SALES_HISTORY.READ']]: { label: 'View all Sales History' },
  [Permission['INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.READ']]: {
    label: 'View Integrated Payments History',
  },
  [Permission['INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.EXPORT']]: {
    label: 'Export Integrated Payments History',
  },

  // ONLINE STORE
  [Permission['ONLINE_ORDERING.ONLINE_STORE.READ']]: {
    label: 'View Online Store',
  },
  [Permission['ONLINE_ORDERING.ONLINE_STORE.WRITE']]: {
    label: 'Manage Online Store',
  },

  //LISTINGS
  [Permission['LISTINGS.READ']]: { label: 'View Listings' },
  [Permission['LISTINGS.WRITE']]: { label: 'Manage Listings' },

  // REWARDS
  [Permission['REWARDS.CAMPAIGN.READ']]: { label: 'View Reward Campaigns' },
  [Permission['REWARDS.CAMPAIGN.WRITE']]: {
    label: 'Create / Edit Reward Campaigns',
  },
  [Permission['REWARDS.REWARDS_LISTING.READ']]: {
    label: 'View Points Redemption Rewards',
  },
  [Permission['REWARDS.REWARDS_LISTING.WRITE']]: {
    label: 'Create / Edit Points Redemption Rewards',
  },

  [Permission['REWARDS.REWARDS_PROGRAMME.READ']]: {
    label: 'View Rewards Programme Details',
  },
  [Permission['REWARDS.REWARDS_PROGRAMME.WRITE']]: {
    label: 'Manage Rewards Programme Settings',
  },
  [Permission['REWARDS.REWARDS_MEMBER.ADD_POINTS']]: {
    label: 'Add Points for Reward Members',
  },
  [Permission['REWARDS.REWARDS_MEMBER.REMOVE_POINTS']]: {
    label: 'Remove Points from Reward Members',
  },
  // REWARD MEMBERS
  [Permission['CUSTOMERS.CUSTOMER.READ']]: { label: 'View Reward Members' },
  [Permission['CUSTOMERS.CUSTOMER_SET.READ']]: {
    label: 'View Reward Members Groups',
  },
  [Permission['CUSTOMERS.CUSTOMER_SET.WRITE']]: {
    label: 'Create / Edit Reward Members Groups',
  },

  // BUSINESS SETTINGS
  [Permission['BUSINESS.SETTINGS.READ']]: { label: 'View Business Settings' },
  [Permission['BUSINESS.SETTINGS.WRITE']]: {
    label: 'Manage Business Settings',
  },

  // TEAMS
  [Permission['TEAMS.ROLE.READ']]: { label: 'View Roles' },
  [Permission['TEAMS.ROLE.WRITE']]: { label: 'Create / Edit Roles' },
  [Permission['TEAMS.TEAM_MEMBER.READ']]: { label: 'View Team Members' },
  [Permission['TEAMS.TEAM_MEMBER.WRITE']]: {
    label: 'Invite / Edit Team Members',
  },

  //POS
  [Permission['POS.REGISTER_SHIFT_REPORT.READ_EXPECTED_AMOUNTS']]: {
    label: 'Read expected amount from the shift on the POS',
  },
  [Permission['POS.CONFIGURATION.READ']]: {
    label: 'View hardware configuration on Point-of-Sale',
  },
  [Permission['POS.CONFIGURATION.WRITE']]: {
    label: 'Edit configuration on Point-of-Sale',
  },
};

export enum ModifierUnit {
  PERCENT = 'PERCENT',
  DOLLAR = 'DOLLAR',
}
export enum TicketType {
  NONE = 'NONE',
  TEXT = 'TEXT', // Free-form text field
  SALE_NUMBER_LAST_2 = 'SALE_NUMBER_LAST_2', // Last 2 digits of the Sales.Sale.saleNumber
  SALE_NUMBER_LAST_3 = 'SALE_NUMBER_LAST_3', // Last 2 digits of the Sales.Sale.saleNumber
}

/**
 * A user generated, publicly available static image
 * Future -- Can have fields like name, caption, transformedUrl etc
 */
export type Image = z.infer<typeof Image.schema>;
export namespace Image {
  export const _type = 'common.image' as const;
  export const schema = z.object({
    id: z.string(),
    _type: z.literal(Image._type),
    url: z.string(),
    thumbnailUrl: z.string(),
    mimeType: z.string(),
    createdAt: z.string().datetime({ offset: true }),
  });

  export const create = (args: Record<string, unknown>): Image => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };
}
/**
 * A user generated, publicly available static generic File type
 */
export type File = z.infer<typeof File.schema>;
export namespace File {
  export const _type = 'common.file' as const;
  export const schema = z.object({
    id: z.string(),
    _type: z.literal(File._type),
    url: z.string(),
    mimeType: z.string(),
    fileName: z.string(),
    createdAt: z.string().datetime({ offset: true }),
  });

  export const create = (args: Record<string, unknown>): File => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };
}

export interface WaffleEvent {
  id: string;
  type: string;
  payload: any;
  version: string;
}

export enum PaymentMethodBrand {
  ALIPAY = 'ALIPAY',
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  DISCOVER = 'DISCOVER',
  FAVEPAY = 'FAVEPAY',
  GRABPAY = 'GRABPAY',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  NETS = 'NETS',
  // This is used for GTO also but currently not in our db
  NETS_FLASHPAY = 'NETS_FLASHPAY',
  PAYNOW = 'PAYNOW',
  SHOPBACK_PAY = 'SHOPBACK_PAY',
  SHOPEEPAY = 'SHOPEEPAY',
  UNIONPAY = 'UNIONPAY',
  UNKNOWN = 'UNKNOWN',
  VISA = 'VISA',

  // Nani stuff
  CASH = 'CASH',
  VOUCHER = 'VOUCHER',
}
