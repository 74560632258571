/**
 * Badge component from shadcn/ui
 *
 * Edits:
 * 1. Added 'flair' and 'success' variants
 */
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../../lib/utils';

const badgeVariants = cva(
  'focus:ring-ring inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'hover:bg-primary-foreground/80 border-transparent bg-slate-100 text-slate-800',
        secondary:
          'hover:bg-secondary-background/80 border-transparent bg-slate-100 text-slate-600',
        flair:
          'bg-flair-foreground text-flair hover:bg-flair-foreground/80 border-transparent',
        success:
          'hover:bg-success-foreground/80 border-transparent bg-green-100 text-green-800',
        destructive:
          'bg-destructive-foreground text-destructive hover:bg-destructive-foreground/80 border-transparent',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  children: React.ReactNode;
  className?: string;
}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
