/**
 * Input component from shadcn/ui
 * We use this as a base component to extend from (eg. image input, number input) most of the time you should be using <TextInput> instead
 *
 * Edits:
 * 1. Add isLoading prop
 * 2. Add leftElement and rightElement props
 */
import * as React from 'react';

import { cn } from '../../lib/utils';
import { Spinner } from './spinner';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  isLoading?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, leftElement, rightElement, isLoading, ...props },
    ref,
  ) => {
    return (
      <div className={cn('relative flex items-center', className)}>
        {leftElement && (
          <div className="absolute left-2 top-1/2 -translate-y-1/2">
            {leftElement}
          </div>
        )}
        <input
          type={type}
          className={cn(
            'border-input bg-background ring-offset-background file:text-foreground placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-sm border px-3 py-2 text-base file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
            leftElement && 'pl-8',
            (rightElement || isLoading) && 'pr-8',
          )}
          ref={ref}
          {...props}
        />
        {isLoading ? (
          <div className="absolute right-2 top-1/2 -translate-y-1/2">
            <Spinner className="text-muted-foreground h-4 w-4" />
          </div>
        ) : (
          rightElement && (
            <div className="absolute right-2 top-1/2 -translate-y-1/2">
              {rightElement}
            </div>
          )
        )}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
